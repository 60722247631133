import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Torasaku.com" keywords={[`application`]} />
    <div style={{marginTop: 200}}>Torasaku.com</div>
  </Layout>
)

export default IndexPage
